@charset 'utf-8';
@import url(https://fonts.googleapis.com/css?family=Lato:400,300,700);
@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-badge;
//@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
//@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
//@include foundation-flex-video;
@include foundation-label;
//@include foundation-media-object;
@include foundation-menu;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;

section {
  @include grid-row;
}
p {color:$cognius-gray;}

// hover effects
/* Underline From Center */
.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  right: 50%;
  bottom: 0;
  background: $primary-color;
  height: 2px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before {
  left: 0;
  right: 0;
}
// header -- topbar
header {background:transparent;}
.logo-type {
  display: block;
  text-indent: -100000px;
  width:175px;
  height: 50px;
  background: url(../img/cognius_logotype.png);
  background-size: 175px 50px;

}
.title-bar {
  background: $white;
  button {
    &.menu-icon::after {
      background:$primary-color;
      box-shadow: 0 7px 0 $primary-color, 0 14px 0 $primary-color;
    }
  }
  .title-bar-title {
    width:75%;
    .logo-type {
      margin:auto;
    }
  }
}
.top-bar {
  background: $light-gray;
  .top-bar-left {display:none;}
  a {
    //color:#ffffff;
    font-size: .8rem;
  }
  .dropdown.menu .submenu {
    background: $light-gray;
    border:none;
  }
  @include breakpoint(medium) {
    background: transparent;
    .top-bar-left {display:block}
    .menu li {
      height:72px;
      a {
        font-size: .9rem;
        font-weight: 700;
      }
    }
    .menu.submenu li {
      height:auto;
    }
    .dropdown.menu .submenu {
      background:rgba(255,255,255, .9);
    }
  }
}

// home page
.hero {
  background: url(../img/graph_bg_sm.png) no-repeat center top;
  height:400px;
  background-size: cover;
  .button {margin:0 0 1rem;}
  @include breakpoint(medium) {
    background: url(../img/graph_bg_med.png) no-repeat center top;
    height:400px;
    background-size: cover;
  }
  @include breakpoint(large) {
    background: url(../img/graph_bg.png) no-repeat center top;
    height:400px;
    background-size: cover;
  }
  .content {
    position: relative;
    top:25px;
    @include grid-col;
    text-align: center;
    .cta {
      margin-top:10px;
    }
  }
}

// shared page elements
.sub-head {
  background: $medium-gray;
  &#join-us {
    background: url(../img/data_connection_bg.jpg) no-repeat top;
    background-size: cover;
  }
  section {
    padding: 20px 0;
    text-align: center;
  }
}

.content {
  @include grid-col;
  padding-top:25px;
  padding-bottom: 25px;
}

// pages
#about {
  .cogo-logo {
    padding:10px;
    background: #000000;
  }
  .hero {
    background: url(../img/dots_bg_sm.png) no-repeat center;
    height:200px;
    background-size: cover;
    margin-bottom: 25px;
    @include breakpoint(medium) {
      background: url(../img/dots_bg.png) no-repeat center;
      background-size: cover;
    }
    &#join-us {
      background: url(../img/data_connection_bg.jpg) no-repeat top;
      background-size: cover;
      text-align: center;
      margin-bottom: 0;
    }
  }
  article {
    @include grid-col;
    @include breakpoint(large) {
      @include grid-col-size(9);
    }
    @include breakpoint(medium) {
      @include grid-col-size(8);
    }
  }
  aside {
    @include grid-col;
    @include breakpoint(large) {
      @include grid-col-size(3);
    }
    @include breakpoint(medium) {
      @include grid-col-size(4);
    }
  }
  section {
    position: relative;
    top: 20%;
    @include grid-row;
  }
}

#careers {
  .hero {
    background: url(../img/data_connection_bg.jpg) no-repeat top;
    background-size: cover;
    height:250px;
    .content {
      top:5px;
      a {
        @include button;
        @include button-style();
      }
    }
  }
  #openings {
    background: $light-gray;
    .jobs--col, .apply--col {
      @include grid-col;
      @include grid-col-size(12);
      #interest {
        .column {
          @include grid-col-collapse();
          label {
            font-size: .785rem;
          }
        }
      }
      .accordion:first-of-type {
        margin-bottom: 0;
        border-bottom: 0;
      }

      .accordion-title {
        font-size: 1.2rem;
        span {
          display: block;
          font-size: .7rem;
          color: rgba(0,0,0,.8);

        }
      }
      .description--job {
        padding-bottom: 20px;
        .toggle {
          margin:20px 0;
        }
        ul {
          list-style-type: disc;
        }
      }
      // recruiterbox overrides
      .rbox-widget {
        .rbox-opening-li {
          @include accordion-title;
          font-size: 1rem;
          border-top: none;
          &:hover {text-decoration: none;}
          .rbox-opening-li-title {
            font-weight: normal;
            &:hover {text-decoration: none;}
          }
        }
        .rbox-btn.rbox-apply-button, button.rbox-btn {
          @include button;
          text-shadow: none!important;
          box-shadow: none!important;
        }
        .rbox-job-shortdesc {
          display: block;
          font-size: .7rem;
          color: rgba(0,0,0,.8);
        }
        .rbox-opening-position-type, .rbox-opening-allows-remote {
          border: 0px solid #ccc;
          padding: 0;
          border-radius: 0px;
          display: inline-block;
          margin-left: 10px;
        }
        &.rbox-opening-detail {padding: 1.25rem 1rem;}
      }
    }
    @include breakpoint(large) {
      .jobs--col {
        @include grid-col-size(7);
      }
      .apply--col {
        @include grid-col-size(5);
      }
    }
  }
}


#perks, #team, #home-1, #home-2 {
  .content {
    text-align: center;
    h3 {text-align: left;}
    .column {
      margin-bottom:20px;
      .fa {
        display: block;
        color: $medium-gray;
      }
    }
  }
}
#index {
  #home-1 {
    .content {
      .columns {
        p {text-align: left;}
      }
    }
  }
  #home-2 {
    background: $light-gray;
  }
}

#team {
  .hero {
    background: url(../img/data_bg.jpg) no-repeat center top;
    height:200px;
    background-size: cover;
    &#join-us {
      background: url(../img/data_connection_bg.jpg) no-repeat top;
      background-size: cover;
      text-align: center;
      margin-bottom: 0;
      .content {
        position: relative;
        top:20%;
        h3 {
          text-align: center;
        }
      }
    }
  }
  .content {
    .exec, .non-exec {
      h5 {
        line-height:1;
      }
      img {
        @include thumbnail;
        height: 100px;
        width: 100px;
        border:3px solid $primary-color;
        border-radius: 100px;
      }
      .fa {
        border:3px solid;
        border-radius: 100px;
        color:$primary-color;
        display: inline-block;
        padding:15px 5px;
        height: 100px;
        width: 100px;
      }
      a {
        display: block;
        .fa {
          border:none;
          padding: 0;
          color: $primary-color;
          height: auto;
          width: auto;
        }
      }
    }
    .non-exec {
      img {
        height:75px;
        width:75px;
      }
      .fa {
        border-width:2px;
        height:75px;
        width:75px;
        padding: 8% 0 0 0;
      }
    }

  }
}

// footer
footer {
  background: $cognius-gray;
  p {color: $light-gray;}
  padding:1.2rem 0;
  .contact {
    @include grid-col;
    @include breakpoint(medium) {
      @include grid-col-size(20%);
    }
  }
  .links {
    @include grid-col;
    @include breakpoint(medium) {
      @include grid-col-size(80%);
    }
  }
  .copyright {
    @include grid-col;
  }
  .contact, .links, .copyright {
    font-size: .8rem;
    color: $medium-gray;
    p {
      margin-bottom: 0;
      line-height: 1.2;
      &:last-child {
        margin-bottom: 1rem;
      }
    }
    h6 {margin-bottom: 0;}
    .menu {
      margin-bottom:1rem;
    }
  }
  .logo-type {
    width:125px;
    height:35px ;
    background-size: 125px 35px;
    background-repeat: no-repeat;
    background-position-x: -6px;
  }

}

//.reveal {
//  top: 150px !important;
//}