// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

/// Hide an element by default, only displaying it above a certain screen size.
/// @param {Keyword} $size - Breakpoint to use. **Must be a breakpoint defined in `$breakpoints`.**
@mixin show-for($size) {
  $size: map-get($breakpoints, $size);
  $size: -zf-bp-to-em($size) - (1/16);

  @include breakpoint($size down) {
    display: none !important;
  }
}

/// Hide an element by default, only displaying it within a certain breakpoint.
/// @param {Keyword} $size - Breakpoint to use. **Must be a breakpoint defined in `$breakpoints`.**
@mixin show-for-only($size) {
  $lower-bound: -zf-bp-to-em(map-get($breakpoints, $size)) - (1/16);
  $upper-bound: -zf-bp-to-em(-zf-map-next($breakpoints, $size));

  @media screen and (max-width: $lower-bound), screen and (min-width: $upper-bound) {
    display: none !important;
  }
}

/// Show an element by default, and hide it above a certain screen size.
/// @param {Keyword} $size - Breakpoint to use. **Must be a breakpoint defined in `$breakpoints`.**
@mixin hide-for($size) {
  @include breakpoint($size) {
    display: none !important;
  }
}

/// Show an element by default, and hide it above a certain screen size.
/// @param {Keyword} $size - Breakpoint to use. **Must be a breakpoint defined in `$breakpoints`.**
@mixin hide-for-only($size) {
  @include breakpoint($size only) {
    display: none !important;
  }
}

@mixin foundation-visibility-classes {
  // Basic hiding classes
  .hide {
    display: none !important;
  }

  .invisible {
    visibility: hidden;
  }

  // Responsive visibility classes
  @each $size in $breakpoint-classes {
    @if $size != small {
      .hide-for-#{$size} {
        @include hide-for($size);
      }

      .show-for-#{$size} {
        @include show-for($size);
      }
    }

    .hide-for-#{$size}-only {
      @include hide-for-only($size);
    }

    .show-for-#{$size}-only {
      @include show-for-only($size);
    }
  }

  // Screen reader visibility classes
  // Need a "hide-for-sr" class? Add aria-hidden="true" to the element
  .show-for-sr,
  .show-on-focus {
    @include element-invisible;
  }

  // Only display the element when it's focused
  .show-on-focus {
    &:active,
    &:focus {
      @include element-invisible-off;
    }
  }

  // Landscape and portrait visibility
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;

    @include breakpoint(landscape) {
      display: block !important;
    }

    @include breakpoint(portrait) {
      display: none !important;
    }
  }

  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;

    @include breakpoint(landscape) {
      display: none !important;
    }

    @include breakpoint(portrait) {
      display: block !important;
    }
  }
}
